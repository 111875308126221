<template>
  <div>
    <div class="flex justify-center" v-if="loading">
        <propagate-loader class="spinner" color="#E29200" :loading="loading" :size="50"></propagate-loader>
    </div>
    <div v-if="is_url_expiration == false">
      <div class="bg-middleOrange mt-2 lg:mt-5">
          <span class="text-white font-semibold ml-5 lg:ml-20 xl:ml-20 text-3xl md:text-4xl lg:text-4xl xl:text-4xl">Datos del Socio</span>
      </div>

      <div class="ml-5 lg:ml-20 xl:ml-20 mt-5 flex flex-wrap justify-center">
        <div class="w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
          <p class="text-middleOrange">R.F.C</p>
          <p class="font-semibold text-xl">{{rfc}}</p>
        </div>

        <div class="w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
          <p class="text-middleOrange">Nombre Completo</p>
          <p class="font-semibold text-xl">{{name}}</p>
        </div>

        <div class="w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
          <p class="text-middleOrange">Teléfono</p>
          <p class="font-semibold text-xl">{{phone}}</p>
        </div>

        <div class="w-full md:w-1/1 lg:w-1/1 xl:w-1/1">
          <p class="text-middleOrange">Correo Electrónico</p>
          <p class="font-semibold text-xl">{{email}}</p>
        </div>
      </div>

      <div class="bg-middleOrange">
        <div class="ml-5 lg:ml-20 xl:ml-20">
          <span class="text-white font-semibold  text-3xl md:text-4xl lg:text-4xl xl:text-4xl">Datos del Centro de Trabajo</span>
        </div>
      </div>

      <div class="ml-5 lg:ml-20 xl:ml-20 mt-5 flex flex-wrap justify-center">
        <div class="w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
          <p class="text-middleOrange">Centro de Trabajo</p>
          <p class="font-semibold text-xl">{{workplace}}</p>
        </div>

        <div class="w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
          <p class="text-middleOrange">Clave</p>
          <p class="font-semibold text-xl">{{work_key}}</p>
        </div>

        <div class="w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
          <p class="text-middleOrange">Domicilio</p>
          <p class="font-semibold text-xl">{{work_address}}</p>
        </div>

        <div class="w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
          <p class="text-middleOrange">Secretaría Gral. o Representante de CT</p>
          <p class="font-semibold text-xl">{{work_secretary}}</p>
        </div>

        <div class="w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
          <p class="text-middleOrange">Delegación o C.T.</p>
          <p class="font-semibold text-xl">{{work_delegation}}</p>
        </div>

        <div class="w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
          <p class="text-middleOrange">Municipio</p>
          <p class="font-semibold text-xl">{{work_township}}</p>
        </div>
      </div>

      <div class="bg-middleOrange mt-2 lg:mt-5">
        <span class="text-white font-semibold ml-5 lg:ml-20 xl:ml-20 text-3xl md:text-4xl lg:text-4xl xl:text-4xl">Datos Laborales</span>
      </div>

      <div class="ml-5 lg:ml-20 xl:ml-20 mt-5 flex flex-wrap justify-center">

        <div class="w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
          <p class="text-middleOrange">Clave(s) de la(s) plaza(s)</p>
          <p class="font-semibold text-xl">{{laboral_key_place}}</p>
        </div>

        <div class="w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
          <p class="text-middleOrange">Fecha de Ingreso al Servicio</p>
          <p class="font-semibold text-xl">{{laboral_income_service}}</p>
        </div>

        <div class="w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
          <p class="text-middleOrange">Años de Servicio</p>
          <p class="font-semibold text-xl">{{laboral_years_service}}</p>
        </div>
      </div>

      <div class="bg-middleOrange mt-2 lg:mt-5">
        <span class="text-white font-semibold ml-5 lg:ml-20 xl:ml-20 text-3xl md:text-4xl lg:text-4xl xl:text-4xl">Beneficios del FOMAQRO</span>
      </div>

      <!-- <div class="ml-5 lg:ml-20 xl:ml-20 mt-5 flex flex-wrap justify-center">
      </div> -->

      <div class="showInSmallScreens text-center">
        <div class="ml-2 mr-2 lg:ml-5 lg:ml-20 xl:ml-20 mt-5  lg:flex-wrap justify-center">
          <div v-for="benefit in benefits_fomaqro" :key="benefit.id" class="w-full">
            <div class="w-full mt-5">
              <p class="text-middleOrange">Beneficio</p>
              <p class="font-semibold">{{benefit.name}}</p>
            </div>
            <div class="w-full inline-flex mt-5">
              <div class="w-1/2">
                <p class="text-middleOrange">Aportación Qnal.</p>
                <p class="font-semibold">{{benefit.amount_forthnight}}</p>
              </div>
              <div class="w-1/2">
                <p class="text-middleOrange text-center">Fecha de Adquisición</p>
                <p class="font-semibold text-center">{{benefit.acquisition_date}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="hideInSmallScreens w-full">
        <table class="xl:mx-20 mt-5 mb-5">
          <tr class="text-middleOrange">
            <th class="text-left font-normal w-1/3">Beneficio</th>
            <th class="text-center font-normal w-1/3">Aportación Qnal.</th>
            <div class="lg:ml-60 mb-5">
              <th class="text-center font-normal w-1/3">Fecha de Adquisición</th>
            </div>
          </tr>
          <tr v-for="benefit in benefits_fomaqro" :key="benefit.id">
            <td>
                <span class="font-semibold w-1/3">{{benefit.name}}</span>
            </td>
            <td class="font-semibold text-center mb-5 w-1/3">{{benefit.amount_forthnight}}</td>
            <div class="lg:ml-60 mb-5">                        
              <td class="font-semibold text-center w-1/3">{{benefit.acquisition_date}}</td>
            </div>
          </tr>
        </table>
      </div>

      <div class="showInSmallScreens text-center" v-if="have_recovery">
        <div class="ml-2 mr-2 lg:ml-5 lg:ml-20 xl:ml-20 mt-5  lg:flex-wrap justify-center">
          <div v-for="other_benefit in other_benefits_fomaqro" :key="other_benefit.id" class="w-full">
            <div class="w-full mt-5">
              <p class="text-middleOrange">Beneficio</p>
              <p class="font-semibold">{{other_benefit.name}}</p>
            </div>
            <div class="w-full inline-flex mt-5">
              <div class="w-1/2">
                <p class="text-middleOrange">V.A.P</p>
                <p class="font-semibold">{{other_benefit.vap}}</p>
              </div>
              <div class="w-1/2">
                <p class="text-middleOrange text-center">Inválidez</p>
                <p class="font-semibold text-center">{{other_benefit.disability}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="hideInSmallScreens w-full" v-if="have_recovery">
        <table class="xl:mx-20 mt-5 mb-5">
          <tr class="text-middleOrange">
            <th class="text-left font-normal"></th>
            <th class="text-left font-normal">V.A.P</th>
            <th class="text-left font-normal">Inválidez</th>
          </tr>
          <tr v-for="other_benefit in other_benefits_fomaqro" :key="other_benefit.id">
            <td>
                <span class="font-semibold text-left">{{other_benefit.name}}</span>
            </td>
            <td class="font-semibold text-left mb-3">{{other_benefit.vap}}</td>                           
            <td class="font-semibold text-left mb-3">{{other_benefit.disability}}</td>
          </tr>
        </table>
      </div>


      <div class="bg-middleOrange mt-2 lg:mt-5">
        <span class="text-white font-semibold ml-5 lg:ml-20 xl:ml-20 text-3xl md:text-4xl lg:text-4xl xl:text-4xl">Beneficios de Salud</span>
      </div>

      <div class="showInSmallScreens text-center">
        <div class="ml-2 mr-2 lg:ml-5 lg:ml-20 xl:ml-20 mt-5  lg:flex-wrap justify-center">
          <div class="w-full mt-5">
            <p class="text-middleOrange">Beneficio</p>
            <p class="font-semibold">Cáncer</p>
          </div>
          <div class="w-full inline-flex mt-5">
            <div class="w-1/2">
              <p class="text-middleOrange">Módulos</p>
              <p class="font-semibold">{{cancer_moduls}}</p>
            </div>
            <div class="w-1/2">
              <p class="text-middleOrange text-center">Protección</p>
              <p class="font-semibold text-center">{{cancer_protection}}</p>
            </div>
            <div class="w-1/2">
              <p class="text-middleOrange text-center">Fecha Alta</p>
              <p class="font-semibold text-center">{{cancer_high_date}}</p>
            </div>
            <div class="w-1/2">
              <p class="text-middleOrange text-center">Aportación Qnal.</p>
              <p class="font-semibold text-center">{{cancer_amount}}</p>
            </div>
          </div>

          <div class="w-full mt-5">
            <p>Dependientes Económicos</p>
          </div>
          <div class="text-center text-xs" colspan="4">
            <div v-for="cancer_dependient in cancer_dependients" :key="cancer_dependient.index">
                {{ cancer_dependient }}
            </div>
          </div>

          <div class="w-full mt-5">
            <p class="text-middleOrange">Beneficio</p>
            <p class="font-semibold">Enfermedades Graves</p>
          </div>
          <div class="w-full inline-flex mt-5">
            <div class="w-1/2">
              <p class="text-middleOrange">Módulos</p>
              <p class="font-semibold">{{eg_moduls}}</p>
            </div>
            <div class="w-1/2">
              <p class="text-middleOrange text-center">Protección</p>
              <p class="font-semibold text-center">{{eg_protection}}</p>
            </div>
            <div class="w-1/2">
              <p class="text-middleOrange text-center">Fecha Alta</p>
              <p class="font-semibold text-center">{{eg_high_date}}</p>
            </div>
            <div class="w-1/2">
              <p class="text-middleOrange text-center">Aportación Qnal.</p>
              <p class="font-semibold text-center">{{eg_amount}}</p>
            </div>
          </div>

          <div class="w-full mt-5">
            <p>Dependientes Económicos</p>
          </div>
          <div class="text-center text-xs" colspan="4">
            <div v-for="eg_dependient in eg_dependients" :key="eg_dependient.index">
                {{ eg_dependient }}
            </div>
          </div>
        </div>
      </div>

      <div class="hideInSmallScreens w-full">
        <table class="xl:mx-20 mt-5 mb-5">
          <tr class="text-middleOrange">
            <th class="text-left font-normal xl:w-1/12 lg:w-1/12">Beneficios</th>
            <th class="text-center font-normal xl:w-1/12 lg:w-1/12">Módulos</th>
            <th class="text-center font-normal xl:w-1/12 lg:w-1/12">Protección</th>
            <th class="text-center font-normal xl:w-1/12 lg:w-1/12">Fecha Alta</th>
            <th class="text-center font-normal xl:w-1/12 lg:w-1/12">Aportación Qnal.</th>
          </tr>
          <tr>
            <td class="font-semibold text-left mb-5">Cáncer</td>
            <td class="font-semibold text-center mb-5">{{cancer_moduls}}</td>                           
            <td class="font-semibold text-center mb-5">{{cancer_protection}}</td>
            <td class="font-semibold text-center mb-5">{{cancer_high_date}}</td>
            <td class="font-semibold text-center mb-5">{{cancer_amount}}</td>
          </tr>
          <tr>
            <td class="text-center">
              Dependientes Económicos
            </td>
            <td class="text-left text-xs" colspan="4">
              <p v-for="cancer_dependient in cancer_dependients" :key="cancer_dependient.index">
                {{ cancer_dependient }}
              </p>
            </td>
          </tr>
          <br>
          <tr>
            <td class="font-semibold text-left mb-5">Enfermedades Graves</td>
            <td class="font-semibold text-center mb-5">{{eg_moduls}}</td>                           
            <td class="font-semibold text-center mb-5">{{eg_protection}}</td>
            <td class="font-semibold text-center mb-5">{{eg_high_date}}</td>
            <td class="font-semibold text-center mb-5">{{eg_amount}}</td>
          </tr>
          <tr>
            <td class="text-center">
              Dependientes Económicos
            </td>
            <td class="text-left text-xs" colspan="4">
              <p v-for="eg_dependient in eg_dependients" :key="eg_dependient.index">
                {{ eg_dependient }}
              </p>
            </td>
          </tr>
        </table>
      </div>



      <div class="bg-middleOrange mt-2 lg:mt-5">
        <span class="text-white font-semibold ml-5 lg:ml-20 xl:ml-20 text-3xl md:text-4xl lg:text-4xl xl:text-4xl">Beneficios</span>
      </div>

      <div class="hideInSmallScreens w-full">
        <table class="xl:mx-20 mt-5 mb-5">
          <tr class="text-middleOrange">
            <th class="text-left font-normal xl:w-1/12 lg:w-1/12">Beneficios Adicionales</th>
            <th class="text-center font-normal xl:w-1/12 lg:w-1/12">Recuperación al término del plazo</th>
            <th class="text-center font-normal xl:w-1/12 lg:w-1/12">Beneficio</th>
            <th class="text-center font-normal xl:w-1/12 lg:w-1/12">Aportación Qnal.</th>
            <th class="text-center font-normal xl:w-1/12 lg:w-1/12">Inválidez</th>
            <th class="text-center font-normal xl:w-1/12 lg:w-1/12">V.A.P</th>
            <th class="text-center font-normal xl:w-1/12 lg:w-1/12">Fecha de Adquisición</th>
          </tr>
          <tr v-for="(benefit, index) in benefits_vt" :key="benefit.id">
            <td>
              <p class="font-semibold">
                <span class="text-middleOrange">{{ index + 1 }}</span> Defunción
              </p>
            </td>
            <td class="font-semibold text-center mb-5">{{benefit.recuperacion}}</td>                           
            <td class="font-semibold text-center mb-5">{{benefit.beneficio}}</td>
            <td class="font-semibold text-center mb-5">{{benefit.costo_qnal}}</td>
            <td class="font-semibold text-center mb-5">{{benefit.invalidez}}</td>
            <td class="font-semibold text-center mb-5">{{benefit.vap}}</td>
            <td class="font-semibold text-center mb-5">{{benefit.fecha_adquisicion}}</td>
          </tr>
        </table>
      </div>

      <div class="showInSmallScreens text-center">
        <div class="ml-2 mr-2 lg:ml-5 lg:ml-20 xl:ml-20 mt-5  lg:flex-wrap justify-center">
          <div v-for="(benefit, index) in benefits_vt" :key="benefit.id" class="w-full">
            <div class="w-full mt-5">
              <p class="text-middleOrange">Beneficios Adicionales</p>
              <p class="font-semibold">
                <span class="text-middleOrange">{{index + 1}}</span> Defunción
              </p>
            </div>
            <div class="w-full inline-flex mt-5">
              <div class="w-1/2">
                <p class="text-middleOrange">Fecha de Adquisición</p>
                <p class="font-semibold">{{benefit.fecha_adquisicion}}</p>
              </div>
              <div class="w-1/2">
                <p class="text-middleOrange text-center">Beneficio</p>
                <p class="font-semibold text-center">{{benefit.beneficio}}</p>
              </div>
            </div>
            <div class="w-full inline-flex mt-5">
              <div class="w-1/2">
                <p class="text-middleOrange">Aportación Qnal.</p>
                <p class="font-semibold">{{benefit.costo_qnal}}</p>
              </div>
              <div class="w-1/2">
                <p class="text-middleOrange text-center">Inválidez</p>
                <p class="font-semibold text-center">{{benefit.invalidez}}</p>
              </div>
            </div>
            <div class="w-full inline-flex mt-5">
              <div class="w-1/2">
                <p class="text-middleOrange">V.A.P</p>
                <p class="font-semibold">{{benefit.vap}}</p>
              </div>
              <div class="w-1/2">
                <p class="text-middleOrange text-center">Recuperación al término del plazo</p>
                <p class="font-semibold text-center">{{benefit.recuperacion}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr class="showInSmallScreens mt-5 mb-5 bg-darkOrange h-px mx-16">
      <hr class="hideInSmallScreens mt-10 bg-darkOrange h-px mx-24">

      <div class="w-full flex justify-center">
        <div class="ml-6 mt-5 mb-5">
          <p class="text-middleOrange text-base font-normal">Aportación Total:</p>
          <p class="font-semibold text-xl text-center"> {{total_cost}}</p>
        </div>
      </div>

      <div class="bg-middleOrange">
        <div class="ml-5 lg:ml-20 xl:ml-20">
          <span class="text-white font-semibold  text-3xl md:text-4xl lg:text-4xl xl:text-4xl">Beneficiarios por fallecimiento del Socio</span>
        </div>
      </div>
    
      <div class="showInSmallScreens text-center">
        <div class="ml-2 mr-2 lg:ml-5 lg:ml-20 xl:ml-20 mt-5 lg:flex-wrap justify-center">
          <div v-for="beneficiaries in beneficiariesDecease" :key="beneficiaries.id" class="w-full">
            <div class="w-full mt-5">
              <p class="text-middleOrange">Nombres</p>
              <p class="font-semibold text-xl">{{beneficiaries.nombre}}</p>
            </div>

            <div class="w-full inline-flex mt-5">
              <div class="w-1/2">
                <p class="text-middleOrange text-center">Parentesco</p>
                <p class="font-semibold text-xl text-center">{{beneficiaries.parentesco}}</p>
              </div>

              <div class="w-1/2">
                <p class="text-middleOrange">Fecha de Nacimiento</p>
                <p class="font-semibold text-xl">{{beneficiaries.fecha_nacimiento}}</p>
              </div>
            </div>

            <div class="w-full mt-5">
              <p class="text-middleOrange text-center">Porcentaje (%)</p>
              <p class="font-semibold text-xl text-center">{{beneficiaries.porcentaje}}</p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="hideInSmallScreens">
          <div class="ml-20 mt-5 flex flex-wrap justify-center">
            <div class="w-1/4">
              <p class="text-middleOrange">Nombres</p>
              <p v-for="beneficiaries in beneficiariesDecease" :key="beneficiaries.id" class="font-semibold text-xl">{{beneficiaries.nombre}}</p>
            </div>

            <div class="w-1/4">
              <p class="text-middleOrange text-center">Parentesco</p>
              <p v-for="beneficiaries in beneficiariesDecease" :key="beneficiaries.id" class="font-semibold text-xl text-center">{{beneficiaries.parentesco}}</p>
            </div>

            <div class="w-1/4">
              <p class="text-middleOrange text-center">Fecha de Nacimiento</p>
              <p v-for="beneficiaries in beneficiariesDecease" :key="beneficiaries.id" class="font-semibold text-xl text-center">{{beneficiaries.fecha_nacimiento}}</p>
            </div>

            <div class="w-1/4">
              <p class="text-middleOrange text-center">%</p>
              <p v-for="beneficiaries in beneficiariesDecease" :key="beneficiaries.id" class="font-semibold text-xl text-center">{{beneficiaries.porcentaje}}</p>
            </div>
          </div>
        </div>
      </div>

      <hr class="showInSmallScreens mt-5 mb-5 bg-middleOrange h-px mx-16">
      <hr class="hideInSmallScreens mt-10 bg-middleOrange h-px mx-24">

      <div class="container mt-10 px-10 lg:px-24">
        <p class="text-xl text-center lg:text-left">
          Autorizo al Comité Técnico para que gestione ante las autoridades correspondientes todos los descuentos vía nómina relativos a las aportaciones estipuladas en el Reglamento FOMAQRO para tener derecho al pago de los beneficiarios.
        </p>
      </div>

      <div class="text-center mt-10 mb-10 px-10 items-center">
        <span>He leído y acepto las condiciones del </span><a class="text-middleOrange" href="" v-on:click.prevent="getPrivacynotice()">Aviso de Privacidad</a>
      </div>

      <div class="formAcceptsConditions mt-5 mb-5 flex justify-center">
          <input v-on:click="(acceptConditions = true)" class="boxAcceptsCondittions" type="radio" name="type" value="Si" id="yesForm">
          <label class="labelAcceptsConditions hover:bg-lightGray text-xl py-1 px-16 m-1" for="yesForm">Sí</label>
          <input v-on:click="(acceptConditions = false)" class="boxAcceptsCondittions" type="radio" name="type" value="No" id="noForm">
          <label class="labelAcceptsConditions text-xl hover:bg-lightGray py-1 px-16 m-1" for="noForm">No</label>
      </div>

      <div class="text-center">
        <p class="text-middleOrange text-2xl font-semibold">Firma Digital</p>
        <p class="px-5">Realice su firma como en su identificación oficial</p>
      </div>

      <div class="w-full mt-5">
        <div class="flex flex-col lg:flex-row justify-center">
            <!-- <div class="w-full"></div> -->
          <div class="w-full flex justify-center">
            <VueSignaturePad
                id="signature"
                width="45%"
                height="150px"
                ref="signaturePad"
                :options="options"
            />
          </div>
            <!-- <div class="w-full"></div> -->
        </div>

        <div class="text-center mt-5 mb-5">
          <!-- <div class="w-1/3"></div>
          <div class="w-1/3"> -->
          <!-- <button @click="save" class="mt-5 bg-darkGray hover:bg-lightGray text-xl py-1 px-20 rounded-full">
                  Ver
          </button> -->
          <button @click="undo" class="mt-5 bg-darkGray hover:bg-lightGray text-xl py-1 px-20 rounded-full">
            Deshacer
          </button>
          <!-- </div> -->
          <div class="w-1/3"></div>
        </div>
      </div>

      <div class="text-center px-5" v-if="acceptConditions == false">
        <span class="text-red-600 text-xl font-semibold">Debes aceptar las condiciones del Aviso de Privacidad para continuar</span>
      </div>

      <div class="text-center px-5" v-if="is_signed_empty == true">
        <span class="text-red-600 text-xl font-semibold">Debes ingresar la firma como identificación oficial para continuar</span>
      </div>

      <div class="text-center px-5" v-if="document_signed == true">
        <span class="text-red-600 text-xl font-semibold">El documento ya ha sido firmado</span>
      </div>

      <div class="flex justify-center w-full mt-10 mb-5" v-if="loading_finish">
        <propagate-loader class="w-full flex justify-center spinner" color="#E29200" :loading_finish="loading_finish" :size="50"></propagate-loader>
      </div>

      <div class="text-center mt-5 mb-5" v-if="show_finish">
        <button @click="goToPDF" class="bg-normalYellow hover:bg-hoverYellow text-white text-xl py-1 px-20 rounded-full">
          Finalizar
        </button>
      </div>
    </div>
    <div v-else-if="is_url_expiration == true">
      <div class="bg-middleOrange mt-2 lg:mt-5">
        <span class="text-white font-semibold ml-5 lg:ml-20 xl:ml-20 text-3xl md:text-4xl lg:text-4xl xl:text-4xl">{{errors}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {  
      acceptConditions: null,
      options: {
          penColor: "#1F2933"
      },
      apiCabsaCore: "https://www.cabsacore.com/",
      apiCcapi: "https://api.cabsacore.com/",//"http://200.92.206.58:8080/"
      token_auth_valid: "",
      loading_finish: false,
      show_finish: true,
      is_previous_letter: false,
      is_new_letter: false,
      is_url_expiration: null,
      url_is_expiration: true,
      is_signed_empty:false,
      errors: null,
      status_signed_url: null,
      url_response_json: null,
      url_response_pdf: null,
      url_path_response_json: null,
      url_path_response_pdf: null,
      name_catalog_document: "",
      url_path_response_pdf_signed: null,
      notice_of_privacy_type: "",
      is_not_document_signed: true,
      urlParams: null,
      file: null,
      json_file: null,
      personal_informatiodocument_name: "",
      url_for_download_pdf: "",
      document_signed: false,
      loading: false,
      have_recovery: false,
      have_decease_benefit: false,
      document_type: "",
      rfc: "",
      name: "",
      phone: "",
      email: "",
      email_associate: "",
      street: "",
      number_exterior: "",
      number_interior: "",
      postal_code: "",
      colony: "",
      township: "",
      federal_entity: "",
      city: "",
      workplace: "",
      work_key: "",
      work_number: "",
      work_address: "",
      work_secretary: "",
      work_delegation: "",
      work_township: "",
      laboral_key_place: "",
      laboral_income_service: "",
      laboral_years_service: "",
      beneficiariesDecease: [],
      benefits_fomaqro: [],
      other_benefits_fomaqro: [],
      benefits_vt: [],
      total_cost: "",

      cancer_moduls: "",
      cancer_protection: "",
      cancer_high_date: "",
      cancer_amount: "",
      cancer_dependients: [],

      eg_moduls: "",
      eg_protection: "",
      eg_high_date: "",
      eg_amount: "",
      eg_dependients: [],
      member_id: 0,
      user_id: 0
    }
  },
  methods: {
    async goToPDF(){ 
      this.show_finish = false
      this.loading_finish = true
      if (this.acceptConditions == true && this.save().isEmpty == false){
        await this.insertDigitalSignatureToFile()
        await this.postUploadFile()
        if (this.is_not_document_signed == true){
          await this.sendEmail()
          localStorage.setItem("pdf_for_download", this.url_for_download_pdf);
          this.$router.push({ name: 'successful-process'});
        }
        this.is_signed_empty = this.save().isEmpty
        this.document_signed = true
      }
      this.getInfoSigned()
      if (this.acceptConditions != true){
        this.acceptConditions = false
      }
      this.loading_finish = false
      this.show_finish = true
    },
    getInfoSigned(){
      if (this.save().isEmpty == true){
        return this.is_signed_empty = true
      }
      return this.is_signed_empty = false
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    save() {
      var result = {
        isEmpty: true,
        data: ""
      }
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if(isEmpty == false){
          result.isEmpty = isEmpty
          result.data = data.split(",")[1]
      }else{
          this.is_signed_empty = isEmpty
      }
      return result
    },
    change() {
      this.options = {
        penColor: "#1F2933"
      };
    },
    resume() {
      this.options = {
        penColor: "#1F2933"
      };
    },
    async insertDigitalSignatureToFile(){

      let base64_signature = this.save().data
      this.registerSignature(base64_signature)

      this.errors = null
      let config = {
        headers: {
        'Content-Type': 'application/json'
        },
      }
      let data = {
        "token_auth": this.token_auth_valid,
        "path_file_to_sign": this.url_path_response_pdf,
        "business_acronym": "FOMAQRO",
        "name_catalog_document": this.document_type,
        "name_catalog_document_notice_of_privacy": this.notice_of_privacy_type,
        "digital_signature": base64_signature
      }
      await this.axios.post(this.apiCcapi + "/digital_signatures/insert_digital_signature_to_file", data, config)
      .then((response) => {
        if (response.data.errors.length == 0) {
          this.url_path_response_pdf_signed = response.data.response.path
        }else {
            this.errors = true
        }
        this.loading = false
      }).catch(error => {
          this.errors = error.response.data.errors
          this.loading = false
      });
    },
    async registerSignature(base64_signature) {
      this.errors = null;
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let data = {
        token_auth: this.token_auth_valid,
        signature_file: base64_signature,
        member_id: this.member_id,
        user_id: this.user_id,
        registered: new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString()
      };
      await this.axios
        .post(this.apiCcapi + "/fomaqro/signature_fondos/create",data,config)
        .then(() => {
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          this.loading = false;
        });
    },
    async postUploadFile(){
      let config = {
        headers: {
          'Content-Type': 'application/json'
        },
      }
      let data = {
        "token_auth": this.token_auth_valid,
        "file": this.url_path_response_pdf_signed,
        "document_name": this.document_type,
        "business_acronym":"FOMAQRO",
        "process":"generate_plan_costs_pdf_signed",
        "extension": "",
        "url_for_sign": window.location.href
      }
      await this.axios.post(this.apiCcapi + "/digital_signatures/upload_signed_file", data, config)
      .then((response) => {
        if (response.data.errors.length == 0) {
          this.url_for_download_pdf = response.data.response.url
        }else {
            this.is_not_document_signed = false
        }
      }).catch(error => {
        this.errors = error.response.data.errors;
        this.is_not_document_signed = false
      });
    },
    async getPdfInformation(){
      this.errors = null
      let config = {
        headers: {
          'Content-Type': 'application/json'
        },
      }
      let data = {
        "token_auth": this.token_auth_valid,
        "file_name": this.file,
        "business_acronym":"FOMAQRO",
        "process":"generate_plan_costs_pdf"
      }
      await this.axios.post(this.apiCcapi + "/digital_signatures/get_file_cloud", data, config)
      .then((response) => {
        if (response.data.errors.length == 0){
          this.url_response_pdf = response.data.response.url
          this.url_path_response_pdf = response.data.response.path
        }else {
          this.errors = response.data.errors
        }
        this.loading = false
      }).catch(error => {
        this.errors = error.response.data.errors
        this.loading = false
      });
    },
    async getJsonInformation(){
      this.errors = null
      let config = {
        headers: {
          'Content-Type': 'application/json'
        },
      }
      let data = {
        "token_auth": this.token_auth_valid,
        "file_name": this.json_file,
        "business_acronym":"FOMAQRO",
        "process":"generate_plan_costs_json"
      }
      await this.axios.post(this.apiCcapi + "/digital_signatures/get_file_cloud", data, config)
      .then((response) => {
        if (response.data.errors.length == 0){
          this.url_response_json = this.apiCcapi + "/digital_signatures/get_tmp_file?path=" + response.data.response.path
          this.getJsonInformationFromUrl();
          this.is_url_expiration = false
        }else {
            this.errors = response.data.errors
        }
        this.loading = false
      }).catch(error => {
        this.errors = error.response.data.errors
        this.loading = false
      });
    },
    getJsonInformationFromUrl(){
      this.axios.get(this.url_response_json, "", "").then((response) => {
        this.personal_information = response.data.datos_personales
        this.rfc = this.personal_information.rfc
        this.name = this.personal_information.nombre_completo
        this.phone = this.personal_information.telefono
        this.email = this.personal_information.correo
        this.email_associate = response.data.correo_usuario
        this.workplace = response.data.datos_centro_trabajo.nombre
        this.work_key = response.data.datos_centro_trabajo.clave
        this.work_number = ""
        this.work_address = response.data.datos_centro_trabajo.domicilio
        this.work_secretary = response.data.datos_centro_trabajo.representante
        this.work_delegation = response.data.datos_centro_trabajo.delegacion
        this.work_township = response.data.datos_centro_trabajo.municipio
        this.laboral_key_place = response.data.datos_laborales.plazas
        this.laboral_income_service = response.data.datos_laborales.fecha_ingreso
        this.laboral_years_service = response.data.datos_laborales.antiguedad
        this.beneficiariesDecease = response.data.beneficiarios
        this.total_cost = response.data.costo_total
        this.benefits_fomaqro.push(
          {
          "name": "Ahorro y Préstamo", 
          "amount_forthnight": response.data.beneficios_fomaqro.ahorro_y_prestamo_costo_qnal, 
          "acquisition_date": response.data.beneficios_fomaqro.ahorro_y_prestamo_fecha_adquisicion
          },
          {
            "name": "Defunción del Agremiado: Esposa(o) Hijo(s) menores de 25 años", 
            "amount_forthnight": response.data.beneficios_fomaqro.defuncion_agremiado_costo_qnal, 
            "acquisition_date": response.data.beneficios_fomaqro.defuncion_agremiado_fecha_adquisicion
          }
        )
        this.benefits_vt = response.data.beneficios
        this.name_catalog_document = response.data.nombre_catalogo_documento
        if (this.name_catalog_document === "plan_costos_nuevo_ingreso_201126") {
          this.have_recovery = true
          this.other_benefits_fomaqro.push({"name":"Recuperación al termino del Plazo", "vap": response.data.beneficios_fomaqro.vap, "disability": response.data.beneficios_fomaqro.invalidez})
          this.document_type = "plan_costos_nuevo_ingreso_201126_firma"
          this.notice_of_privacy_type = "plan_costos_nuevo_ingreso_201126_reglamento"
        }
        if (this.name_catalog_document === "plan_costos_ya_inscrito_201126") {
          this.benefits_fomaqro.push({"name": "Retiro", "amount_forthnight": response.data.beneficios_fomaqro.retiro_costo_qnal, "acquisition_date": response.data.beneficios_fomaqro.retiro_fecha_adquisicion})
          this.document_type = "plan_costos_ya_inscrito_201126_firma"
          this.notice_of_privacy_type = "plan_costos_ya_inscrito_201126_reglamento"
        }
        if (this.name_catalog_document === "plan_costo_rechazado_240117") {
          this.have_recovery = true
          this.other_benefits_fomaqro.push({"name":"Recuperación al termino del Plazo", "vap": response.data.beneficios_fomaqro.vap, "disability": response.data.beneficios_fomaqro.invalidez})
          this.document_type = "plan_costo_rechazado_240117_firma"
          this.notice_of_privacy_type = "plan_costo_rechazado_240117_reglamento"
        }

        this.cancer_moduls = response.data.cancer.cancer_socio_modulos
        this.cancer_protection = response.data.cancer.cancer_socio_proteccion
        this.cancer_high_date = response.data.cancer.cancer_socio_fecha_alta
        this.cancer_amount = response.data.cancer.cancer_socio_aportacion_quincenal
        this.cancer_dependients = response.data.cancer.cancer_dependientes_economicos

        this.eg_moduls = response.data.enfermedades_graves.enfermedades_graves_socio_modulos
        this.eg_protection = response.data.enfermedades_graves.enfermedades_graves_socio_proteccion
        this.eg_high_date = response.data.enfermedades_graves.enfermedades_graves_socio_fecha_alta
        this.eg_amount = response.data.enfermedades_graves.enfermedades_graves_socio_aportacion_quincenal
        this.eg_dependients = response.data.enfermedades_graves.enfermedades_graves_dependientes_economicos

        this.user_id = response.data.usuario_id;
        this.member_id = response.data.socio_id;
      });
    },
    getPrivacynotice(){
      window.open("https://cabsacloud.ams3.digitaloceanspaces.com/store/FOMAQRO/Aviso%20de%20privacidad%20FOMAQRO.pdf")
    },
    encryptMessage(){
      const header = { alg: "HS256", typ: "JWT" };
      const encodedHeader = Buffer.from(JSON.stringify(header)).toString(
        "base64"
      );
      var data = {
        html:
          "Informacion del interesado<br>Nombre: " +
          this.name +
          "<br>Correo: " +
          this.email +
          "<br>Celular: " +
          this.phone +
          "<br><br><br>"
      };
      const encodedPayload = Buffer.from(JSON.stringify(data)).toString(
        "base64"
      );
      var jwtSecret = "u3E*aWShrxOv";
      const crypto = require("crypto");
      const signature = crypto
        .createHmac("sha256", jwtSecret)
        .update(encodedHeader + "." + encodedPayload)
        .digest("base64");
      return encodedHeader + "." + encodedPayload + "." + signature;
    },
    async sendEmail(){
      var emailToSendFirst = ""
      var emailToSendSecond = ""
      if (this.email !="" && this.email_associate == "" ){
          emailToSendFirst = this.email
      }
      if (this.email =="" && this.email_associate != ""){
          emailToSendFirst = this.email_associate
      }
      if (this.email !="" && this.email_associate != ""){
          emailToSendFirst = this.email
          emailToSendSecond = this.email_associate
      }
      let emails = [];
      emails.push(emailToSendFirst);
      if (emailToSendSecond != "") {
        emails.push(emailToSendSecond);
      }
      let message = this.encryptMessage()
      this.axios.post(this.apiCcapi + "/send_mail", {
        business: "fomaqro",
        mail: {
          business: "fomaqro",
          process_type: "firma designacion beneficiarios",
          title: "Firma Digital FOMAQRO",
          body_mail: message, 
          mailing_list: emails,
          attachment_list: [
            {
              file: this.url_for_download_pdf,
              name:"FirmaDigitalFOMAQRO.pdf"
            }
          ]
        }
      })
      .then(response => {
          console.log(response.data)
          this.email = ""
      }).catch(error => {
          console.log(error.data)
      });
    },
    async postLogin(){
      let config = {
        headers: {
            'Content-Type': 'application/json'
        },
      }

      let data = {
        user: {
          email: "aplm@gcabsa.com.mx",
          password: "cabsa2022",
        },
      };

      await this.axios.post(this.apiCcapi + '/fas/login', data, config)
        .then((dataa) => {
          this.token_auth_valid = dataa.data.token_auth
          localStorage.setItem("token_auth_valid", this.token_auth_valid);
      })
    },
    async getStatusDigitalSignature(){
      let config = {
        headers: {
            'Content-Type': 'application/json'
        },
      }
      this.status_signed_url = false
      await this.axios.get(this.apiCcapi + "/digital_signatures/get_status_digital_signature", 
        { 
          params: { 
            "token_auth": this.token_auth_valid, 
            "url": window.location.toString(), 
            "business_acronym": "FOMAQRO"
          }
        }, config).then((response) => {
        if (response.data.response.url_status == "FIRMADO"){
          this.status_signed_url = true
        }
      })
    },
    async postUrlExpiration(){
      let config = {
          headers: {
              'Content-Type': 'application/json'
          },
      }
      this.loading = true
      await this.axios.post(this.apiCcapi + "/digital_signatures/expiry_time", 
        { 
          "token_auth": this.token_auth_valid, 
          "url": window.location.toString(), 
          "business_acronym": "FOMAQRO"
        }, 
        config
      )
      .then((response) => {
        if (response.data.errors.length == 0) {
          this.url_is_expiration = false
          return true
        }
      })
      .catch(error => {
        error.response.data.errors
        this.errors = "El documento que desea firmar ya ha vencido, favor de contactar a su asesor."
        this.url_is_expiration = true
        this.is_url_expiration = true
        this.loading = false
        return false
      });
      await this.getStatusDigitalSignature()
      if (this.status_signed_url == true){
        this.errors = "El documento que desea firmar ya ha sido firmado, favor de contactar a su asesor."
        this.url_is_expiration = true
        this.is_url_expiration = true
        return false
      }
    }
  },
  async beforeMount() {
    this.urlParams = new URLSearchParams(window.location.search)
    this.file = this.urlParams.get('file')
    this.json_file = this.urlParams.get('json_file')
    await this.postLogin()
    await this.postUrlExpiration()
    if (this.url_is_expiration == false){
        this.getJsonInformation()
        this.getPdfInformation()
    }
    this.loading = false
  }
}

</script>

<style>
#signature {
  border: double 2px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #E29200, #E29200);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

@media only screen and (max-width: 650px) {
    .hideInSmallScreens {
        display: none;
    }
}

@media only screen and (min-width: 650px) {
    .showInSmallScreens {
        display: none;
    }
}

.formAcceptsConditions .labelAcceptsConditions{
  padding: 6px 30px;
  border-radius: 20px;
  border: 2px solid  #E4E7EB;
}

.boxAcceptsCondittions:checked + label{
  background: #CBD2D9;
  color: #E4E7EB;
  border: 2px solid #CBD2D9;
}

.boxAcceptsCondittions{
  display: none;
}

</style>