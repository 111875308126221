  <template>
    <div>
    <div class="flex infline-flex">
        <img class="mt-10" style="display: block;
            margin-left: auto;
            margin-right: auto;
            width:130px;
            height:150px;" alt="Vue logo" src="@/assets/logo_fomaqro.png">
    </div>
    <div>
      <p class="text-4xl text-center text-black font-bold w-full mt-5">Fondo Magisterial de Querétaro</p>
    </div>
    </div>
</template>

<script>
export default {
  name: 'Header',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
