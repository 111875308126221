import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import VueSignature from "vue-signature-pad"
import axios from 'axios'
import VueAxios from 'vue-axios'
import { VueSpinners } from '@saeris/vue-spinners'
import "@/assets/css/tailwind.css"

Vue.config.productionTip = false
Vue.use(VueSignature)
Vue.use(VueAxios, axios)
Vue.use(VueSpinners)

new Vue({
  router: router,
  render: h => h(App),
}).$mount('#app')
